import { graphql, Link } from "gatsby";
import React from "react";
import "bootstrap/scss/bootstrap.scss";
import NavBar from "../components/nav/NavBar";
import Footer from "../components/footer/Footer";
import {
  renderRichText,
} from "gatsby-source-contentful/rich-text";
import ArticleCardSet from "../components/article/ArticleCardSet";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArticleProps } from "../components/article/Article";
import { SEO } from "../components/seo/seo";

const ContentfulPage = (props: ArticleProps): React.ReactElement<any> => {
  const { contentfulArticleGallery, allContentfulArticle } = props.data;
  const lastNode = allContentfulArticle.nodes.length - 1;
  return (
    <>
      <NavBar />
      {
        contentfulArticleGallery.featuredContent.map((featuredContent, i) => {
          return <ArticleCardSet {...featuredContent} />
          })
        }
        <div className="container-lg">
          <div className="articles-wrapper">
                     {
             allContentfulArticle.nodes.map((article, index) => {
               return (
                 <div className="d-flex flex-column flex-md-row mb-3" key={`${article.title}_${index}`}>
                    <div className="col-12 col-md-4">
                    {
                      article.hero && 
                      <GatsbyImage
                        alt="article image"
                        style={{ width: '100%' }}
                        image={getImage(article.hero.backgroundImage)}
                      />
                    }
                    </div>
                    <div className="col-12 col-md-8 p-3">
                      <div className="h4 text-uppercase"> { article.title } </div>
                      <div style={{fontSize: '14px'}}> { article.subheadline && renderRichText(article.subheadline) } </div>
                      <div style={{fontSize: '14px'}}> { article.shortDescription && renderRichText(article.shortDescription) } </div>
                      <Link className="link-primary" to={article.pagePath}>
                        Read Full Article
                      </Link>
                    </div>
                    {
                      index !== lastNode ?
                      <hr className="mb-0" />
                      :
                      null
                    }
                  </div>
               )
             })
           }
          </div>
        </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query ($id: String, $includedArticleTypes: [String]) {
    contentfulArticleGallery(id: { eq: $id }) {
      pageMetadata {
        ... on ContentfulPageMetadata {
          title
          description
          keywords
          canonicalUrl
        }
      }
      includedArticleTypes
      featuredContent {
          ... on ContentfulArticleCardSet {
          __typename
          eyebrow
          articles {
            ... on ContentfulArticle {
              title
              pagePath
              headline
              articleType
              subheadline {
                raw
              }
              shortDescription {
                raw
              }
              hero {
                ... on ContentfulHero {
                  __typename
                  backgroundImage {
                    gatsbyImageData(height: 215, width: 370)
                  }
                }
              }
            }
          }
        }
      }
    }
    allContentfulArticle(filter: {articleType: {in: $includedArticleTypes}}) {
      nodes {
        title
        pagePath
        headline
        subheadline {
          raw
        }
        shortDescription {
          raw
        }
        youtubeVideoId
        body {
          raw
        }
        hero {
          ... on ContentfulHero {
            __typename
            backgroundImage {
              gatsbyImageData(height: 215, width: 370)
            }
          }
        }
      }
    }
  }
`;

export default ContentfulPage;

export const Head = ({ data }) => {
  const { pageMetadata } = data.contentfulArticleGallery;
  return <SEO metadata={pageMetadata} />
}
