import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';
import { ArticleProps } from './Article';
import './ArticleCard.scss';

const ArticleCard = (props: ArticleCardProps) => {
  const { article } = props;
  return (
    <div className="article-card">
      {article.hero && <GatsbyImage
        style={{width: '100%'}}
        alt=""
        image={getImage(article.hero.backgroundImage)}
      />}
      <div className="p-3">
        <div className="row">
          <div className="col">
            <div className="ArticleCard_eyebrow">
              { article.articleType }
            </div>
            <hr />
            <div className="ArticleCard_headline">
              { article.headline }
            </div>
            {
              article.subheadline && 
              <div className="ArticleCard_subheadline">
                { renderRichText(article.subheadline) }
                </div>
            }
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <Link to={article.pagePath}>
              Read Full Article
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ArticleCardProps {
  article: ArticleProps;
}

export default ArticleCard;
